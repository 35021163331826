import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../components/Layout';
import Seo from '../../components/SEO';
import CodeBlock from '../../components/CodeBlock';
import H1 from '../../components/H1';
import H2 from '../../components/H2';
import NarrowContainer from '../../components/NarrowContainer';

const gtmSnippet = `<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-K8CD3DF');</script>
<!-- End Google Tag Manager -->`;

const gtmSnippetNoScript = `<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K8CD3DF"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->`;

const Page = ({ data, location }) => {
  const { pathname } = location;
  const {
    imgThemes,
    imgEditCode,
    imgOpenTheme,
    imgRemoveSnippet,
    imgRemoveSnippetNoScript,
  } = data;
  return (
    <Layout>
      <Seo
        title="How to remove Google Tag Manager snippets from Shopify"
        slug={pathname}
      />
      <NarrowContainer>
        <H1>How to remove Google Tag Manager snippets from Shopify</H1>

        <H2>1. Go to your "Themes"</H2>
        <p>
          Go to Shopify Admin, and from the left-hand menu select “Themes” under
          "Online Store”
        </p>
        <Img fluid={imgThemes.childImageSharp.fluid} alt={imgThemes.name} />

        <H2>2. Open theme editor</H2>
        <p>
          From the right side under “Current theme” select “Actions” and then
          “Edit code”. This will open your theme editor.
        </p>
        <Img fluid={imgEditCode.childImageSharp.fluid} alt={imgEditCode.name} />

        <H2>3. Open “theme.liquid” file</H2>
        <p>
          In theme editor open “theme.liquid” which is in the “Layout” folder.
        </p>
        <Img
          fluid={imgOpenTheme.childImageSharp.fluid}
          alt={imgOpenTheme.name}
        />

        <H2 className="font-bold text-xl my-3">
          4. Delete the main Google Tag Manager snippet
        </H2>
        <p>
          Find the Google Tag Manager snippet between{' '}
          <strong>{`<head>`}</strong> and <strong>{`</head>`}</strong> tags, and
          remove it.
          <CodeBlock code={gtmSnippet} language="html" />
        </p>
        <Img
          fluid={imgRemoveSnippet.childImageSharp.fluid}
          alt={imgRemoveSnippet.name}
        />

        <H2>
          5. Scroll down and delete the "noscript" Google Tag Manager snippet
        </H2>
        <p>
          Find the "noscript" Google Tag Manager snippet between{' '}
          <strong>{`<body>`}</strong> and <strong>{`</body>`}</strong> tags, and
          remove it.
          <CodeBlock code={gtmSnippetNoScript} language="html" />
        </p>
        <Img
          fluid={imgRemoveSnippetNoScript.childImageSharp.fluid}
          alt={imgRemoveSnippetNoScript.name}
        />

        <H2>6. Save “theme.liquid” file</H2>

        <p>Finally, click "Save".</p>
      </NarrowContainer>
    </Layout>
  );
};

Page.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default Page;

export const data = graphql`
  query {
    imgThemes: file(name: { eq: "go-to-shopify-admin-online-store-themes" }) {
      name
      childImageSharp {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgEditCode: file(
      name: { eq: "in-shopify-admin-themes-select-edit-code" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgOpenTheme: file(
      name: { eq: "in-shopify-theme-editor-open-theme-liquid" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgRemoveSnippet: file(
      name: { eq: "in-shopify-theme-editor-remove-gtm-snippet" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgRemoveSnippetNoScript: file(
      name: { eq: "in-shopify-theme-editor-remove-gtm-snippet-noscript" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
