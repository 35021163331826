import React from 'react';

const H1 = ({ children }) => (
  <h1 className="my-8 mb-16 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
    {children}
  </h1>
);

H1.propTypes = {};
H1.defaultProps = {};

export default H1;
