import React from 'react';

const H2 = ({ children }) => (
  <h2 className="font-bold text-xl my-3">{children}</h2>
);

H2.propTypes = {};
H2.defaultProps = {};

export default H2;
