import React from 'react';

const NarrowContainer = ({ children }) => (
  <div className="relative px-3 md:px-0 mx-auto overflow-hidden prose max-w-3xl py-6 text-gray-800">
    {children}
  </div>
);

NarrowContainer.propTypes = {};
NarrowContainer.defaultProps = {};

export default NarrowContainer;
